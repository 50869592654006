import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './TextHeader.module.css';

class TextHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    white: PropTypes.bool,
    tag: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    tag: 'h1',
    white: false,
    className: '',
  };

  render() {
    const { className, children, tag: Tag, white } = this.props;
    const rootStyles = cn([styles.root], className, { [styles.white]: white });

    return <Tag className={rootStyles}>{children}</Tag>;
  }
}

export default TextHeader;
