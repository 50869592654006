import React, { Component } from 'react';

import Appear from 'components/Appear';
import InViewport from 'components/InViewport';

import styles from './Meet.module.css';

class Meet extends Component {
  render() {
    return (
      <section className={styles.root} id="meet">
        <div className={styles.container}>
          <InViewport>
            {inViewport => (
              <Appear appeared={inViewport} duration="longDuration" animation="fadeIn">
                <div className={styles.greeting}>
                  Мы помогаем
                  <br /> бизнесу
                </div>
              </Appear>
            )}
          </InViewport>
        </div>
      </section>
    );
  }
}

export default Meet;
