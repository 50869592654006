import React, { Component } from 'react';
import cn from 'classnames';

import Header from 'components/Header';
import Meet from 'components/Meet';
import About from 'components/About';
import Activity from 'components/Activity';
import Tools from 'components/Tools';
import Learn from 'components/Learn';
import Vacancies from 'components/Vacancies';
import Contacts from 'components/Contacts';
import VacanciesDetail from 'components/VacanciesDetail';
import MobileMenu from 'components/MobileMenu';
import vacancies from 'vacancies';

import { checkVacanciesUrl, parseIdFromUrl } from './utils/URL';

import styles from './App.module.css';

class App extends Component {
  state = {
    isVacanciesDetailOpen: false,
    vacancyId: null,
    isMobileMenuOpen: false,
  };

  componentDidMount() {
    const self = this;

    this.checkUrl();

    window.onhashchange = () => {
      self.checkUrl();
    };
  }

  onVacancyClick = id => {
    this.setState({ isVacanciesDetailOpen: true, vacancyId: id });
  };

  handleChooseVacancy = id => {
    this.setState({ vacancyId: id });
  };

  closeModal = () => {
    this.setState({ isVacanciesDetailOpen: false });
  };

  handleOpenMobileMenu = () => {
    this.setState({ isMobileMenuOpen: true });
    document.body.style.overflow = 'hidden';
  };

  handleCloseMobileMenu = () => {
    this.setState({ isMobileMenuOpen: false });
    document.body.style.overflow = 'auto';
  };

  checkUrl() {
    const url = document.location.hash;

    if (checkVacanciesUrl(url)) {
      const id = parseIdFromUrl(url);
      this.setState({ isVacanciesDetailOpen: true, vacancyId: id });
      return;
    }

    this.setState({ isVacanciesDetailOpen: false, vacancyId: null });
  }

  render() {
    const { isMobileMenuOpen, isVacanciesDetailOpen, vacancyId } = this.state;
    const appStyles = cn({ [styles.menuOpened]: isMobileMenuOpen }, styles.root);

    return (
      <div className={appStyles}>
        <div className={styles.mobileMenu}>
          <MobileMenu opened={isMobileMenuOpen} onClose={this.handleCloseMobileMenu} />
        </div>
        <Header onMobileMenuClick={this.handleOpenMobileMenu} />
        <div id="main" className="section">
          <Meet />
        </div>
        <div id="about" className="section">
          <About />
        </div>
        <div id="capability" className="section">
          <Activity />
        </div>
        <div id="technologies" className="section">
          <Tools />
        </div>
        <div id="learn" className="section">
          <Learn />
        </div>
        <div id="vacancies" className="section">
          <Vacancies vacancies={vacancies} onVacancyClick={this.onVacancyClick} />
        </div>
        <div id="contacts" className="section">
          <Contacts />
        </div>
        <VacanciesDetail
          isOpen={isVacanciesDetailOpen}
          onChooseVacancy={this.handleChooseVacancy}
          vacancies={vacancies}
          id={vacancyId}
          onCloseModal={this.closeModal}
        />
      </div>
    );
  }
}

export default App;
