import { Component } from 'react';
import PropTypes from 'prop-types';
import withViewport from 'react-in-viewport';

class InViewport extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    inViewport: PropTypes.bool.isRequired,
  };

  render() {
    const { inViewport, children: renderChildren } = this.props;

    return renderChildren(inViewport);
  }
}

export default withViewport(InViewport);
