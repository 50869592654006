import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import styles from './Modal.module.css';

import close from './close.svg';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.73)',
  },
};

const appElement = document.getElementById('root');

class Modal extends Component {
  static defaultProps = {
    useCloseIcon: false,
  };

  static propTypes = {
    useCloseIcon: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  closeModal = () => {
    const { onCloseModal } = this.props;
    onCloseModal();
  };

  render() {
    const { children, useCloseIcon, isOpen } = this.props;

    return (
      <div className={styles.root}>
        <ReactModal
          isOpen={isOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          appElement={appElement}
          closeTimeoutMS={500}
          className={styles.modal}
        >
          {useCloseIcon && (
            <button onClick={this.closeModal} type="button" className={styles.closeButton}>
              <img className={styles.closeIcon} src={close} alt="Закрыть" />
            </button>
          )}
          {children}
        </ReactModal>
      </div>
    );
  }
}

export default Modal;
