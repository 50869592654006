import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './TwoSide.module.css';

class TwoSide extends Component {
  static propTypes = {
    reverse: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    reverse: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    const { reverse, children } = this.props;
    const rootStyles = cn([styles.root], { [styles.reverse]: reverse });

    return (
      <section className={rootStyles}>
        <div className={styles.container}>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </section>
    );
  }
}

export default TwoSide;
