import React, { Component, Fragment } from 'react';

import TwoSide from 'components/TwoSide';
import Appear from 'components/Appear';
import InViewport from 'components/InViewport';
import image from './image.png';
import mobileDots from './mobileDots.svg';
import desktopDots from './desktopDots.svg';

import styles from './Learn.module.css';

class Learn extends Component {
  render() {
    return (
      <TwoSide>
        <InViewport>
          {inViewport => (
            <Fragment>
              <div className={styles.content}>
                <div>
                  <Appear appeared={inViewport} duration="longDuration" appearFrom="left">
                    <h1 className={styles.primaryHeader}>Обучение</h1>
                  </Appear>
                  <Appear delay="usualDelay" appeared={inViewport} duration="longDuration" appearFrom="left">
                    <p className={styles.paragraph}>
                      Нам интересно инвестировать в подготовку людей с нуля и под наши задачи, поэтому мы создали наш
                      курс по fullstack-разработке.
                    </p>
                    <p className={styles.paragraph}>Учиться можно как удалённо, так и очно, став нашим стажёром.</p>
                  </Appear>
                </div>
                <Appear delay="longDelay" appeared={inViewport} duration="longDuration" appearFrom="left">
                  <a className={styles.learn} href="https://learn.dualboot.ru/">
                    <span className={styles.linkText}>Начать обучение</span>
                  </a>
                </Appear>
              </div>
              <div className={styles.media}>
                <Appear appeared={inViewport} duration="longDuration" appearFrom="bottom">
                  <div className={styles.desktopDots}>
                    <img alt="Обучение" src={desktopDots} />
                  </div>
                  <div className={styles.mobileDots}>
                    <img alt="Обучение" src={mobileDots} />
                  </div>
                  <img className={styles.image} src={image} alt="Обучение" />
                </Appear>
              </div>
            </Fragment>
          )}
        </InViewport>
      </TwoSide>
    );
  }
}

export default Learn;
