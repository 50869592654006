import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Button.module.css';

class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    primary: PropTypes.bool,
    info: PropTypes.bool,
    id: PropTypes.number,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    primary: false,
    info: false,
    id: null,
    className: 'root',
  };

  handleClick = () => {
    const { id, onClick } = this.props;
    onClick(id);
  };

  render() {
    const { className, children, primary, info } = this.props;
    const rootStyles = cn([styles.root], className, { [styles.primary]: primary }, { [styles.info]: info });

    return (
      <button type="button" className={rootStyles} onClick={this.handleClick}>
        {children}
      </button>
    );
  }
}

export default Button;
