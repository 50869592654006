import React, { Component, Fragment } from 'react';

import Appear from 'components/Appear';
import InViewport from 'components/InViewport';

import TwoSide from 'components/TwoSide';
import image from './image.png';
import desktopDots from './desktopDots.svg';

import styles from './About.module.css';

class About extends Component {
  render() {
    return (
      <TwoSide>
        <InViewport>
          {inViewport => (
            <Fragment>
              <div className={styles.content}>
                <div>
                  <Appear appeared={inViewport} duration="longDuration" appearFrom="left">
                    <h1 className={styles.primaryHeader}>О нас</h1>
                  </Appear>
                  <Appear delay="usualDelay" appeared={inViewport} duration="longDuration" appearFrom="left">
                    <p className={styles.paragraph}>
                      Мы – российское отделение американской компании, созданной опытными бизнесменами,
                      специализирующимися на продвижении <br /> компаний на рынке, продажах и увеличении доходов.
                    </p>
                    <p className={styles.paragraph}>Мы находимся в Ульяновске, а все наши клиенты находятся в США.</p>
                  </Appear>
                </div>
                <Appear delay="longDelay" appeared={inViewport} duration="longDuration" appearFrom="left">
                  <h2 className={styles.secondaryHeader} tag="h2">
                    Наша команда —<br /> это команда настоящих профессионалов.
                  </h2>
                </Appear>
              </div>
              <div className={styles.media}>
                <Appear appeared={inViewport} duration="longDuration" appearFrom="bottom">
                  <img className={styles.desktopDots} src={desktopDots} alt="О нас" />
                  <img className={styles.image} src={image} alt="О нас" />
                </Appear>
              </div>
            </Fragment>
          )}
        </InViewport>
      </TwoSide>
    );
  }
}

export default About;
