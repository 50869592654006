const regex = /^#vacancies\/[0-9]+$/g;

export function checkVacanciesUrl(url) {
  return !!url.match(regex);
}

export function parseIdFromUrl(url) {
  const parsedData = url.split('/');
  const id = parsedData[1];

  return parseInt(id, 10);
}
