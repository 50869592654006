import React, { Component } from 'react';

import Appear from 'components/Appear';
import TextHeader from 'components/TextHeader';
import InViewport from 'components/InViewport';

import mail from './mail.svg';
import dotsDesktop from './dotsDesktop.svg';
import dotsMobile from './dotsMobile.svg';

import styles from './Contacts.module.css';

class Contacts extends Component {
  render() {
    return (
      <section className={styles.root}>
        <div className={styles.container}>
          <InViewport>
            {inViewport => (
              <Appear appeared={inViewport} delay="shortDelay" duration="longDuration" appearFrom="bottom">
                <div className={styles.info}>
                  <div className={styles.dotsDesktop}>
                    <img alt="Контакты" src={dotsDesktop} />
                  </div>
                  <div className={styles.dotsMobile}>
                    <img alt="Контакты" src={dotsMobile} />
                  </div>
                  <TextHeader className={styles.header}>Контакты</TextHeader>
                  <div className={styles.address}>
                    <div className={styles.item}>
                      <img src={mail} alt="email" />

                      <a href="mailto:job@dualbootpartners.com" className={styles.mailLink}>
                        job@dualbootpartners.com
                      </a>
                    </div>
                  </div>
                </div>
              </Appear>
            )}
          </InViewport>
        </div>
      </section>
    );
  }
}

export default Contacts;
