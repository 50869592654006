import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import InViewport from 'components/InViewport';
import Appear from 'components/Appear';
import TextHeader from 'components/TextHeader';
import Button from 'components/Button';

import desktopBgJobs from './desktopBgJobs.svg';

import styles from './Vacancies.module.css';

const settings = {
  className: 'slider variable-width',
  slidesToShow: 4,
  swipeToSlide: true,
  arrows: false,
  infinite: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class Vacancies extends Component {
  static propTypes = {
    vacancies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onVacancyClick: PropTypes.func.isRequired,
  };

  handleClick = id => {
    const { onVacancyClick } = this.props;
    onVacancyClick(id);
  };

  renderVacancy = vacancy => (
    <div className={styles.vacancy} key={vacancy.id}>
      <div className={styles.details}>
        <div className={styles.title}>{vacancy.title}</div>
        <div>
          <Button info id={vacancy.id} onClick={this.handleClick}>
            Подробнее
          </Button>
        </div>
      </div>
    </div>
  );

  renderVacancies = vacancies => {
    const { length } = vacancies;
    const visibleVacancies = vacancies.filter(vacancy => !vacancy.hidden);

    if (length === 1) {
      const vacancy = vacancies[0];

      return <div className={styles.singleVacancy}>{this.renderVacancy(vacancy)}</div>;
    }

    return <Slider {...settings}>{visibleVacancies.map(this.renderVacancy)}</Slider>;
  };

  render() {
    const { vacancies } = this.props;

    return (
      <section className={styles.root}>
        <div>
          <InViewport>
            {inViewport => (
              <Fragment>
                <div className={styles.container}>
                  <div className={styles.description}>
                    <Appear appeared={inViewport} duration="longDuration" appearFrom="bottom">
                      <TextHeader>Вакансии</TextHeader>
                    </Appear>
                    <Appear delay="usualDelay" appeared={inViewport} duration="longDuration" appearFrom="bottom">
                      <p className={styles.paragraph}>
                        Мы много работаем, количество проектов растёт, и поэтому мы постоянно находимся <br />в поиске
                        новых коллег. Для всех вакансий гарантированы интересные проекты в адекватном коллективе с
                        минимумом бюрократии, прямым общением с заказчиками, а также много всяких приятных штук,
                        улучшающих качество жизни на рабочем месте.
                      </p>
                    </Appear>
                  </div>
                </div>
                <div className={styles.container}>
                  <Appear delay="longDelay" appeared={inViewport} duration="longDuration" appearFrom="bottom">
                    <img className={styles.dotsDesktop} src={desktopBgJobs} alt="Вакансии" />
                  </Appear>
                  <Appear delay="longDelay" appeared={inViewport} duration="longDuration" appearFrom="bottom">
                    <div className={styles.slider}>{this.renderVacancies(vacancies)}</div>
                  </Appear>
                </div>
              </Fragment>
            )}
          </InViewport>
        </div>
      </section>
    );
  }
}

export default Vacancies;
