import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import data from 'vacancies';
import Vacancies from './components/Vacancies';

import styles from './VacanciesDetail.module.css';

class VacanciesDetail extends Component {
  static defaultProps = {
    id: null,
  };

  static propTypes = {
    id: PropTypes.number,
    onCloseModal: PropTypes.func.isRequired,
    onChooseVacancy: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    vacancies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  createMarkup = content => {
    return { __html: content };
  };

  renderVacancies = () => {
    const { vacancies } = this.props;
    const visibleVacancies = vacancies.filter(vacancy => !vacancy.hidden);

    return (
      <ul className={styles.vacancies}>
        {visibleVacancies.map(vacancy => (
          <li className={styles.vacancies}>
            <div>{vacancy.title}</div>
          </li>
        ))}
      </ul>
    );
  };

  renderVacancy = () => {
    const { id } = this.props;
    const vacancy = data.find(i => i.id === id);

    if (vacancy) {
      const { title, description } = vacancy;

      return (
        <div className={styles.vacancy}>
          <div className={styles.head}>
            <h1 className={styles.title}>{title}</h1>
          </div>
          <div className={styles.description} dangerouslySetInnerHTML={this.createMarkup(description)} />
          <a className={styles.link} href="mailto:job@dualbootpartners.com">
            Откликнуться
          </a>
        </div>
      );
    }

    return <div>ничего не найдено</div>;
  };

  onCloseModal = () => {
    const { onCloseModal } = this.props;
    onCloseModal();
    window.history.pushState({}, '', `#vacancies`);
  };

  render() {
    const { id, vacancies, onChooseVacancy, isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} useCloseIcon onCloseModal={this.onCloseModal}>
        <div className={styles.root}>
          <Vacancies id={id} vacancies={vacancies} onChooseVacancy={onChooseVacancy} />
          {this.renderVacancy()}
        </div>
      </Modal>
    );
  }
}

export default VacanciesDetail;
