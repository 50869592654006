import React, { Component, Fragment } from 'react';

import InViewport from 'components/InViewport';
import TwoSide from 'components/TwoSide';
import Appear from 'components/Appear';

import technologies from 'images/technologies.svg';

import styles from './Tools.module.css';

class Tools extends Component {
  render() {
    return (
      <TwoSide>
        <div className={styles.media}>
          <div className={styles.picture}>
            <img className={styles.image} src={technologies} alt="Мы используем" />
          </div>
        </div>
        <div className={styles.content}>
          <InViewport>
            {inViewport => (
              <Fragment>
                <Appear appeared={inViewport} duration="longDuration" appearFrom="right">
                  <h1 className={styles.header}>Технологии</h1>
                </Appear>
                <Appear appeared={inViewport} duration="longDuration" appearFrom="right">
                  <div className={styles.technologies}>
                    <div className={styles.block}>
                      <h2 className={styles.title}>Backend:</h2>
                      <ul className={styles.list}>
                        <li>Ruby/Rails</li>
                        <li>Node.js</li>
                        <li>Python</li>
                        <li>Java</li>
                        <li>Elixir/Phoenix</li>
                        <li>PostgreSQL, Firebase, MongoDB, Mysql</li>
                      </ul>
                    </div>
                    <div className={styles.block}>
                      <h2 className={styles.title}>Frontend:</h2>
                      <ul className={styles.list}>
                        <li>React, Vue, Angular</li>
                        <li>Redux</li>
                        <li>ES6, TypeScript</li>
                        <li>Webpack</li>
                      </ul>
                    </div>
                    <div className={styles.block}>
                      <h2 className={styles.title}>DevOps:</h2>
                      <ul className={styles.list}>
                        <li>Docker</li>
                        <li>Kubernetes</li>
                        <li>AWS / GCP / Heroku</li>
                        <li>Terraform</li>
                        <li>Ansible</li>
                      </ul>
                    </div>
                    <div className={styles.block}>
                      <h2 className={styles.title}>Design:</h2>
                      <ul className={styles.list}>
                        <li>Sketch</li>
                        <li>InVision</li>
                        <li>Figma</li>
                      </ul>
                    </div>
                  </div>
                </Appear>
              </Fragment>
            )}
          </InViewport>
        </div>
      </TwoSide>
    );
  }
}

export default Tools;
