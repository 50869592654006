import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Vacancies.module.css';

class Vacancies extends Component {
  static defaultProps = {
    id: null,
  };

  static propTypes = {
    id: PropTypes.number,
    onChooseVacancy: PropTypes.func.isRequired,
    vacancies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  handleChooseVacancy = id => () => {
    const { onChooseVacancy } = this.props;

    onChooseVacancy(id);
  };

  renderVacancy = vacancy => {
    const { id } = this.props;
    const className = classnames({ [styles.vacancy]: true, [styles.active]: id === vacancy.id });

    return (
      <div
        role="button"
        tabIndex="0"
        onKeyPress={this.handleChooseVacancy(vacancy.id)}
        onClick={this.handleChooseVacancy(vacancy.id)}
        className={className}
        key={vacancy.id}
      >
        <div className={styles.title}>{vacancy.title}</div>
      </div>
    );
  };

  render() {
    const { vacancies } = this.props;
    const visibleVacancies = vacancies.filter(vacancy => !vacancy.hidden);

    return <div className={styles.vacancies}>{visibleVacancies.map(this.renderVacancy)}</div>;
  }
}

export default Vacancies;
