import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Scrollspy from 'react-scrollspy';
import withViewport from 'react-in-viewport';

import Appear from 'components/Appear';

import logoWhite from './logoWhite.svg';
import logoBlue from './logoBlue.svg';
import logoDark from './logoDark.svg';
import logoPink from './logoPink.svg';
import logoPurple from './logoPurple.svg';
import logoDarkGreen from './logoDarkGreen.svg';
import logoRed from './logoRed.svg';

import styles from './Header.module.css';

const SECTIONS = ['main', 'about', 'capability', 'technologies', 'learn', 'vacancies', 'contacts'];

class Header extends Component {
  static propTypes = {
    onMobileMenuClick: PropTypes.func.isRequired,
    inViewport: PropTypes.bool.isRequired,
  };

  state = {
    headerColor: 'white',
  };

  handleUpdateMenu = ({ id: currentSection }) => {
    switch (currentSection) {
      case 'about': {
        this.setState({ headerColor: 'blue' });
        break;
      }
      case 'capability': {
        this.setState({ headerColor: 'dark' });
        break;
      }
      case 'technologies': {
        this.setState({ headerColor: 'pink' });
        break;
      }
      case 'learn': {
        this.setState({ headerColor: 'red' });
        break;
      }
      case 'vacancies': {
        this.setState({ headerColor: 'darkGreen' });
        break;
      }
      default:
        this.setState({ headerColor: 'white' });
        break;
    }
  };

  resolveLogoPath = headerColor => {
    switch (headerColor) {
      case 'blue': {
        return logoBlue;
      }
      case 'dark': {
        return logoDark;
      }
      case 'pink': {
        return logoPink;
      }
      case 'purple': {
        return logoPurple;
      }
      case 'darkGreen': {
        return logoDarkGreen;
      }
      case 'red': {
        return logoRed;
      }
      default:
        return logoWhite;
    }
  };

  handleMobileMenuClick = () => {
    const { onMobileMenuClick } = this.props;
    onMobileMenuClick();
  };

  renderMenuIcon = () => {
    return (
      <button className={styles.burger} onClick={this.handleMobileMenuClick} type="button">
        <span className={styles.burgerBox}>
          <span className={styles.burgerInner} />
        </span>
      </button>
    );
  };

  render() {
    const { inViewport } = this.props;
    const { headerColor } = this.state;

    const containerStyles = cn([styles.container], styles[headerColor]);
    return (
      <div className={[styles.root]}>
        <Appear appeared={inViewport} duration="longDuration" appearFrom="top">
          <div className={containerStyles}>
            <div className={styles.inner}>
              <a href="#main">
                {<img className={styles.logo} src={this.resolveLogoPath(headerColor)} alt="Dualboot Partners" />}
              </a>
              <div className={styles.navContainer}>
                <nav className={styles.nav}>
                  <Scrollspy
                    items={SECTIONS}
                    currentClassName={styles.current}
                    offset={-50}
                    onUpdate={this.handleUpdateMenu}
                  >
                    <li>
                      <a className={styles.hidden} title="Главная" href="#main">
                        Главная
                      </a>
                    </li>
                    <li>
                      <a title="O нас" href="#about">
                        O нас
                      </a>
                    </li>
                    <li>
                      <a title="Что мы делаем" href="#capability">
                        Что мы делаем
                      </a>
                    </li>
                    <li>
                      <a title="Технологии" href="#technologies">
                        Технологии
                      </a>
                    </li>
                    <li>
                      <a title="Обучение" href="#learn">
                        Обучение
                      </a>
                    </li>
                    <li>
                      <a title="Вакансии" href="#vacancies">
                        Вакансии
                      </a>
                    </li>
                    <li>
                      <a title="Контакты" href="#contacts">
                        Контакты
                      </a>
                    </li>
                  </Scrollspy>
                </nav>
                <a className={styles.resume} href="mailto:job@dualbootpartners.com">
                  <span className={styles.linkText}>Отправить резюме</span>
                </a>
                {this.renderMenuIcon()}
              </div>
            </div>
          </div>
        </Appear>
      </div>
    );
  }
}

export default withViewport(Header);
