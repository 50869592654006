import React, { Component, Fragment } from 'react';

import InViewport from 'components/InViewport';
import TwoSide from 'components/TwoSide';
import Appear from 'components/Appear';

import strategy from 'images/strategy.svg';
import design from 'images/design.svg';
import web from 'images/web.svg';
import mobile from 'images/mobile.svg';

import image from './image.png';
import desktopDots from './desktopDots.svg';

import styles from './Activity.module.css';

class Activity extends Component {
  render() {
    return (
      <TwoSide reverse header="Что мы делаем">
        <div className={styles.media}>
          <div className={styles.picture}>
            <img className={styles.desktopDots} src={desktopDots} alt="Что мы делаем" />
            <img className={styles.image} src={image} alt="Что мы делаем" />
          </div>
        </div>
        <div className={styles.content}>
          <InViewport>
            {inViewport => (
              <Fragment>
                <Appear appeared={inViewport} duration="longDuration" appearFrom="right">
                  <h1 className={styles.header}>Что мы делаем</h1>
                </Appear>
                <Appear appeared={inViewport} duration="longDuration" delay="usualDelay" appearFrom="right">
                  <p className={styles.paragraph}>
                    Разрабатываем веб и мобильные проекты <br />в b2b-сегменте, а также осуществляем технический
                    менеджмент, разрабатываем дизайн <br /> и обеспечиваем качество продукта (QA).
                  </p>
                </Appear>
                <Appear appeared={inViewport} duration="longDuration" delay="longDelay" appearFrom="right">
                  <div className={styles.features}>
                    <div className={styles.item}>
                      <div className={styles.icon}>
                        <img src={strategy} alt="Стратегия" />
                      </div>
                      <div className={styles.text}>Стратегия</div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.icon}>
                        <img src={design} alt="Дизайн" />
                      </div>
                      <div className={styles.text}>Дизайн</div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.icon}>
                        <img src={web} alt="Web разработка" />
                      </div>
                      <div className={styles.text}>
                        Web <br />
                        разработка
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.icon}>
                        <img src={mobile} alt="Мобильная разработка" />
                      </div>
                      <div className={styles.text}>
                        Мобильная <br />
                        разработка
                      </div>
                    </div>
                  </div>
                </Appear>
              </Fragment>
            )}
          </InViewport>
        </div>
      </TwoSide>
    );
  }
}

export default Activity;
