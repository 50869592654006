import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import closeIcon from './closeIcon.svg';

import styles from './MobileMenu.module.css';

const menuItems = [
  { title: 'O нас', anchor: 'about' },
  { title: 'Возможности', anchor: 'capability' },
  { title: 'Технологии', anchor: 'technologies' },
  { title: 'Обучение', anchor: 'learn' },
  { title: 'Вакансии', anchor: 'vacancies' },
  { title: 'Контакты', anchor: 'contacts' },
];

class MobileMenu extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  renderMenuItem = (text, anchor) => {
    return (
      <li onClick={this.handleClose} role="presentation" key={anchor}>
        <a href={`#${anchor}`}>{text}</a>
      </li>
    );
  };

  render() {
    const { opened } = this.props;
    const rootStyles = cn([styles.root], {
      [styles.menuOpened]: opened,
    });
    return (
      <div className={rootStyles}>
        <button type="button" className={styles.closeIcon} onClick={this.handleClose}>
          <img src={closeIcon} alt="Закрыть" />
        </button>
        <ul>{menuItems.map(({ title, anchor }) => this.renderMenuItem(title, anchor))}</ul>
        <a href="mailto:job@dualbootpartners.com" className={styles.sendResume}>
          Отправить резюме
        </a>
      </div>
    );
  }
}

export default MobileMenu;
