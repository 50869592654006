import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Appear.module.css';

const resolveInitialClass = appearFrom => {
  switch (appearFrom) {
    case 'bottom':
      return styles.fromBottom;
    case 'top':
      return styles.fromTop;
    case 'left':
      return styles.fromLeft;
    case 'right':
      return styles.fromRight;
    default:
      return styles.fromBottom;
  }
};

const resolveAnimationClass = animation => {
  switch (animation) {
    case 'fade':
      return styles.fadeEnter;
    default:
      return styles.fadeEnter;
  }
};

class Appear extends Component {
  static defaultProps = {
    duration: 'usualDuration',
    delay: 'shortestDelay',
    animation: 'fade',
    appearFrom: 'bottom',
    className: 'appear',
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    animation: PropTypes.string,
    appearFrom: PropTypes.string,
    duration: PropTypes.string,
    delay: PropTypes.string,
    className: PropTypes.string,
    appeared: PropTypes.bool.isRequired,
  };

  state = {
    wasShown: false,
  };

  componentDidUpdate = () => {
    const { appeared } = this.props;
    const { wasShown } = this.state;

    if (appeared && !wasShown) {
      this.setState({
        wasShown: appeared,
      });
    }
  };

  render() {
    const { children, animation, appearFrom, duration, delay, className } = this.props;
    const { wasShown } = this.state;

    const enterClass = resolveAnimationClass(animation, appearFrom);
    const initialClass = resolveInitialClass(appearFrom);

    const resultClassName = classnames(
      [className],
      styles.base,
      styles[duration],
      styles[delay],
      { [enterClass]: wasShown },
      { [initialClass]: !wasShown },
    );

    return <div className={resultClassName}>{children}</div>;
  }
}

export default Appear;
